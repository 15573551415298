import Footer from "../components/Footer";
import { useEffect, useState } from "react";
import axios from "axios";
import { GetNameChoose } from "../../funcUtils";
import { useNavigate } from "react-router-dom";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import AnimatedPage from "../components/AnimatedPage";
import swal from "sweetalert";

function HistoryAll() {
    const [isShow, setShow] = useState(false);
    const [ls, setLs] = useState(null);
    function formatDate(m) {
        new Date(m);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2) +
            "  " +
            ("0" + m.getHours()).slice(-2) +
            ":" +
            ("0" + m.getMinutes()).slice(-2);
        return dateString;
    }
    const [history, setHistory] = useState(null);
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem("profile")));
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1);
    };
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/payment/chuyentien`, {})
            .then((res) => {
                setHistory(res.data.data);
            })
            .catch((err) => function () {});
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                if (profile != res.data.data) {
                    setProfile(res.data.data);
                    localStorage.setItem("profile", JSON.stringify(res.data.data));
                }
            })
            .catch((err) => localStorage.removeItem("user"));
    }, []);
    return (
        <>
            <div className="title-top">
                <div className="back" onClick={handleBackClick}>
                    <KeyboardArrowLeftOutlinedIcon />
                </div>
                <h1 className="title-h1">Lịch sử chuyển tiền sảnh</h1>
            </div>
            <div className="main">
                {history != null ? (
                    <div className="content-history" style={{ margin: "0.2rem 0 0" }}>
                        <AnimatedPage>
                            {history?.map((item, key) => (
                                <>
                                    <div
                                        className="item_inner"
                                        onClick={() => {
                                            setLs(item);
                                            setShow(true);
                                        }}>
                                        <div className="item_history">
                                            <div className="title_item_history">
                                                <span className="sanh" style={{color:"#fff"}}>{item?.type_payment}</span>
                                                <span
                                                    className={`type_state ${
                                                        item?.status_payment === "Pending"
                                                            ? "pending"
                                                            : item?.status_payment === "Success"
                                                            ? "win"
                                                            : item?.status_payment === "Deny"
                                                            ? "lose"
                                                            : "cancelled"
                                                    }`}>
                                                    {item?.status_payment === "Pending"
                                                        ? "Đang xử lý"
                                                        : item?.status_payment === "Success"
                                                        ? "Thành công"
                                                        : item?.status_payment === "Deny"
                                                        ? "Thất bại"
                                                        : "Đã hủy"}
                                                </span>
                                            </div>
                                            <div className="id_history_sanh">{item?.detail}</div>
                                        </div>
                                        <div className="money_history">
                                            <span className="money">{Number(item?.money).toLocaleString("vi-VN")}₫</span>
                                            <div className="time_choose">{formatDate(new Date(item?.createdAt))}</div>
                                        </div>
                                    </div>
                                </>
                            ))}
                        </AnimatedPage>
                    </div>
                ) : null}

                <Footer profile={profile} />

                {isShow === true && ls?.status_payment !== "Pending" ? (
                    <>
                        <div className="modal" style={{ zIndex: "9999999" }}>
                            <div className="modaloverlay">
                                <i className="ti-close closelogin"></i>
                            </div>
                            <div className="modalbody">
                                <div>
                                    <div className="modalinner" style={{ padding: "10px 15px" }}>
                                        <div className="modalheader" style={{ padding: "10px 0 20px" }}>
                                            Chi tiết cược
                                        </div>

                                        {ls?.id_bet?.id_bet || ls?.id_bet ? (
                                            <>
                                                <div className="lsgd-table">
                                                    <div>Trò chơi</div>
                                                    <div>{ls?.sanh}</div>
                                                </div>
                                                <div className="lsgd-table">
                                                    <div>Phiên</div>
                                                    <div>{ls?.id_bet?.id_bet ? ls?.id_bet?.id_bet : ls?.id_bet ? ls?.id_bet : "Không xác định"}</div>
                                                </div>
                                                <div className="lsgd-table">
                                                    <div>Thời gian</div>
                                                    <div>{formatDate(new Date(ls?.createdAt))}</div>
                                                </div>
                                                <div className="lsgd-table">
                                                    <div>Đặt cược</div>
                                                    <div>{GetNameChoose(ls?.state, ls?.type, ls?.sanh) ? GetNameChoose(ls?.state, ls?.type, ls?.sanh) : "Chọn " + ls?.state}</div>
                                                </div>
                                                <div className="lsgd-table">
                                                    <div>Tổng Cược</div>
                                                    <div>{Number(ls?.money).toLocaleString("vi-VN")} ₫</div>
                                                </div>
                                                <div className="lsgd-table">
                                                    <div>Tổng thắng</div>
                                                    <div>{Number(ls?.moneythang).toLocaleString("vi-VN")} ₫</div>
                                                </div>
                                            </>
                                        ) : null}
                                        <div>
                                            <div className="modalformcontrols">
                                                <button
                                                    onClick={() => setShow(false)}
                                                    className="popup-close"
                                                    style={{
                                                        background: "#0064ff",
                                                        boxShadow: "none",
                                                        textShadow: "none"
                                                    }}>
                                                    ĐÓNG
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : null}
            </div>
        </>
    );
}
export default HistoryAll;
