import { Link, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import SwiperCore, { Autoplay } from "swiper/core";
import { useEffect, useState } from "react";
import axios from "axios";
import CampaignIcon from "@mui/icons-material/Campaign";
import CloseIcon from "@mui/icons-material/Close";
import AnimatedPage from "../components/AnimatedPage";
import swal from "sweetalert";
import { useForm } from "react-hook-form";

function Home() {
    SwiperCore.use([Autoplay]);
    const swiperParams = {
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        }
    };
    const swiperMenu = {
        autoplay: false,
        slidesPerView: 5.3,
        centerInsufficientSlides: true,
        spaceBetween: 10,
        grabCursor: true
    };
    const swiperGame = {
        autoplay: false,
        slidesPerView: 2,
        centeredSlides: true,
        centeredSlidesBounds: true,
        spaceBetween: 15,
        grabCursor: true
    };
    const swiperGame2 = {
        autoplay: false,
        slidesPerView: 2.5,
        centerInsufficientSlides: true,
        spaceBetween: 20,
        grabCursor: true
    };
    const swiperGame3 = {
        autoplay: false,
        slidesPerView: 2.2,
        centeredSlides: true,
        centeredSlidesBounds: true,
        spaceBetween: 10,
        grabCursor: true
    };
    const swiperGame7 = {
        autoplay: false,
        slidesPerView: 1.4,
        centeredSlides: true,
        centeredSlidesBounds: true,
        spaceBetween: 10,
        grabCursor: true
    };
    const searchParams = new URLSearchParams(window.location.search);
    const notifyParam = searchParams.get("notify");
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem("profile")));
    const [notify, setNotify] = useState(JSON.parse(localStorage.getItem("notify")));
    const [bet, setBet] = useState(JSON.parse(localStorage.getItem("xsmb")));
    const [loadGame, setLoadGame] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [game, setGame] = useState(null);
    const [title, setTitle] = useState(null);
    const [isShow, setShow] = useState(true);
    const [isShow2, setShow2] = useState(false);
    const [playGame, setPlayGame] = useState(null);
    const [product, setProduct] = useState(null);
    const [newMoney, setNewMoney] = useState(null);
    const [balance, setBalance] = useState(0);
    const [sanh, setSanh] = useState(null);
    const [type, setType] = useState(null);
    const [load, setLoad] = useState(false);
    const { register, handleSubmit } = useForm();
    const handleType = (option) => {
        setType(option);
    };
    const navigate = useNavigate();
    const getProfile = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                if (profile != res.data.data) {
                    setProfile(res.data.data);
                    localStorage.setItem("profile", JSON.stringify(res.data.data));
                }
            })
            .catch((err) => localStorage.removeItem("user"));
    };
    useEffect(() => {
        getProfile();
        axios.get(`${process.env.REACT_APP_API_URL}/auth/getnotify`, {}).then((res) => {
            if (notify != res.data.data) {
                setNotify(res.data.data);
                localStorage.setItem("notify", JSON.stringify(res.data.data));
            }
        });
        axios.get(`https://mu88.live/api/front/open/lottery/history/list/1/miba`).then((res) => {
            setBet(res.data.t);
            if (bet != res.data.t) {
                setBet(res.data.t);
                localStorage.setItem("xsmb", JSON.stringify(res.data.t));
            }
        });
    }, []);
    useEffect(() => {
        axios.post(`${process.env.REACT_APP_API_URL}/casino/create`, {});
    }, [profile]);
    const [activeOption, setActiveOption] = useState("1");
    const handleOptionClick = (option) => {
        setActiveOption(option);
    };
    const date0 = new Date();
    const currentHour = date0.getHours();
    const [dateMT, setDateMT] = useState(date0.getDay());
    const [dataMT, setDataMT] = useState();
    const [imageMT, setImageMT] = useState([]);
    const [dateMN, setDateMN] = useState(date0.getDay());
    const [dataMN, setDataMN] = useState([]);
    const [imageMN, setImageMN] = useState([]);
    useEffect(() => {
        if (dateMT == 0) {
            if (currentHour < 18) {
                setDataMT(["khho", "kotu", "thth"]);
                setImageMT(["khanhhoa", "komtum", "hue"]);
            } else {
                setDataMT(["phye", "thth"]);
                setImageMT(["phuyen", "hue"]);
            }
        }
        if (dateMT == 1) {
            if (currentHour < 18) {
                setDataMT(["phye", "thth"]);
                setImageMT(["phuyen", "hue"]);
            } else {
                setDataMT(["dalak", "quna"]);
                setImageMT(["daklak", "quangnam"]);
            }
        }
        if (dateMT == 2) {
            if (currentHour < 18) {
                setDataMT(["dalak", "quna"]);
                setImageMT(["daklak", "quangnam"]);
            } else {
                setDataMT(["dana", "khho"]);
                setImageMT(["danang", "khanhhoa"]);
            }
        }
        if (dateMT == 3) {
            if (currentHour < 18) {
                setDataMT(["dana", "khho"]);
                setImageMT(["danang", "khanhhoa"]);
            } else {
                setDataMT(["qubi", "bidi", "qutr"]);
                setImageMT(["quangbinh", "binhdinh", "quangtri"]);
            }
        }
        if (dateMT == 4) {
            if (currentHour < 18) {
                setDataMT(["qubi", "bidi", "qutr"]);
                setImageMT(["quangbinh", "binhdinh", "quangtri"]);
            } else {
                setDataMT(["gila", "nith"]);
                setImageMT(["gialai", "ninhthuan"]);
            }
        }
        if (dateMT == 5) {
            if (currentHour < 18) {
                setDataMT(["gila", "nith"]);
                setImageMT(["gialai", "ninhthuan"]);
            } else {
                setDataMT(["dana", "qung", "dano"]);
                setImageMT(["danang", "quangngai", "daknong"]);
            }
        }
        if (dateMT == 6) {
            if (currentHour < 18) {
                setDataMT(["dana", "qung", "dano"]);
                setImageMT(["danang", "quangngai", "daknong"]);
            } else {
                setDataMT(["khho", "kotu", "thth"]);
                setImageMT(["khanhhoa", "komtum", "hue"]);
            }
        }
    }, [dateMT]);
    useEffect(() => {
        if (dateMN == 0) {
            if (currentHour < 17) {
                setDataMN(["dalat", "kigi", "tigi"]);
                setImageMN(["dalat", "kiengiang", "tiengiang"]);
            } else {
                setDataMN(["cama", "doth", "tphc"]);
                setImageMN(["camau", "dongthap", "tphcm"]);
            }
        }
        if (dateMN == 1) {
            if (currentHour < 17) {
                setDataMN(["cama", "doth", "tphc"]);
                setImageMN(["camau", "dongthap", "tphcm"]);
            } else {
                setDataMN(["bali", "vuta", "betr"]);
                setImageMN(["baclieu", "vungtau", "bentre"]);
            }
        }
        if (dateMN == 2) {
            if (currentHour < 17) {
                setDataMN(["bali", "vuta", "betr"]);
                setImageMN(["baclieu", "vungtau", "bentre"]);
            } else {
                setDataMN(["cath", "dona", "sotr"]);
                setImageMN(["cantho", "dongnai", "soctrang"]);
            }
        }
        if (dateMN == 3) {
            if (currentHour < 17) {
                setDataMN(["cath", "dona", "sotr"]);
                setImageMN(["cantho", "dongnai", "soctrang"]);
            } else {
                setDataMN(["angi", "bith", "tani"]);
                setImageMN(["angiang", "binhthuan", "tayninh"]);
            }
        }
        if (dateMN == 4) {
            if (currentHour < 17) {
                setDataMN(["angi", "bith", "tani"]);
                setImageMN(["angiang", "binhthuan", "tayninh"]);
            } else {
                setDataMN(["bidu", "trvi", "vilo"]);
                setImageMN(["binhduong", "travinh", "vinhlong"]);
            }
        }
        if (dateMN == 5) {
            if (currentHour < 17) {
                setDataMN(["bidu", "trvi", "vilo"]);
                setImageMN(["binhduong", "travinh", "vinhlong"]);
            } else {
                setDataMN(["biph", "hagi", "loan", "tphc"]);
                setImageMN(["binhphuoc", "haugiang", "longan", "tphcm"]);
            }
        }
        if (dateMN == 6) {
            if (currentHour < 17) {
                setDataMN(["biph", "hagi", "loan", "tphc"]);
                setImageMN(["binhphuoc", "haugiang", "longan", "tphcm"]);
            } else {
                setDataMN(["dalat", "kigi", "tigi"]);
                setImageMN(["dalat", "kiengiang", "tiengiang"]);
            }
        }
    }, [dateMN]);

    function isMobileDevice() {
        if (window.innerWidth < 800) {
            return true;
        }
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    function launchGame(params) {
        if (profile === null) {
            navigate("/login");
            return;
        }
        if (isLoading == true) return;
        const form = {
            ...params,
            platform: isMobileDevice() == true ? "html5" : "html5-desktop"
        };
        setLoading(true);
        let a;
        switch (params.product_type) {
            case "4":
                a = "AG";
                break;
            case "79":
                a = "BBIN";
                break;
            case "104":
                a = "CMD";
                break;
            case "16":
                a = "CQ9";
                break;
            case "27":
                a = "DG";
                break;
            case "86":
                a = "Evolution";
                break;
            case "142":
                a = "FB";
                break;
            case "140":
                a = "JILI";
                break;
            case "121":
                a = "KingMaker";
                break;
            case "102":
                a = "V8 Poker";
                break;
            case "123":
                a = "King Poker";
                break;
            case "43":
                a = "MG";
                break;
            case "39":
                a = "PP";
                break;
            case "174":
                a = "SABA";
                break;
            case "118":
                a = "WM";
                break;
            case "112":
                a = "SEXY";
                break;
            case "54":
                a = "SBO";
                break;
            case "151":
                a = "UG";
                break;
            case "99":
                a = "TF";
                break;
            case "111":
                a = "Playtech";
                break;
            case "28":
                a = "AllBet";
                break;
            case "55":
                a = "JDB";
                break;
            case "202":
                a = "WS168";
                break;
            default:
                a = "";
                break;
        }
        setSanh(a);
        setProduct(params.product_type);
        axios
            .post(`${process.env.REACT_APP_API_URL}/casino/launchgame`, form)
            .then((res) => {
                if (res.data.data.status == 0) {
                    setPlayGame(res.data.data.game_url);
                    axios
                        .post(`${process.env.REACT_APP_API_URL}/casino/getbalance`, { product_type: params.product_type })
                        .then((res) => {
                            setBalance(res.data.data.balance);
                            setLoading(false);
                        })
                        .catch((err) => swal("Đã xảy ra lỗi", "Vui lòng đăng xuất và đăng nhập lại", "error"));
                } else {
                    setLoading(false);
                    swal("Sảnh đang bảo trì", "Vui lòng chọn sảnh khác", "error");
                }
            })
            .catch((err) => {
                setLoading(false);
                if (err.response.data.data.tokenVerificationData.access == false) {
                    swal("Đã xảy ra lỗi", "Vui lòng đăng xuất và đăng nhập lại", "error");
                    return;
                }
                swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã có lỗi xảy ra", "error");
            });
    }
    const menuGame = (title, product_type, game_type) => {
        setTitle(title);
        setLoading(true);
        axios
            .post(`${process.env.REACT_APP_API_URL}/casino/getgamelist`, { product_type, game_type })
            .then((res) => {
                if (res.data.data.length == 0) {
                    swal("Thông báo", "Sảnh game bảo trì", "error");
                    setLoading(false);
                } else {
                    setGame(res.data.data[0]);
                    setLoading(false);
                    setShow2(true);
                }
            })
            .catch((err) => {
                swal("Thông báo", "Sảnh game bảo trì", "error");
                setLoading(false);
            });
    };
    const onSubmit = (data) => {
        if (load === false) {
            if (!sanh) {
                swal("Thông báo", "Vui lòng chọn sảnh", "warning");
                return;
            }
            const formData = {
                product_type: product,
                type: type,
                money: Number(data.money.replaceAll(".", "").replaceAll(",", ""))
            };
            if (!type) {
                swal("Chú ý", "Vui lòng chọn chuyển tiền vào hoặc chuyển tiền ra", "warning");
                return;
            }
            setLoad(true);
            axios
                .post(`${process.env.REACT_APP_API_URL}/casino/transfer`, formData)
                .then((res) => {
                    if (type == "2") {
                        swal("Thành công", `Chuyển quỹ ra khỏi sảnh thành công!`, "success").then(() => window.location.reload());
                    } else {
                        swal("Thành công", `Chuyển quỹ vào sảnh thành công!`, "success").then(() => window.location.reload());
                    }
                    axios
                        .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
                        .then((res) => {
                            setProfile(res.data.data);
                        })
                        .catch((err) => localStorage.removeItem("user"));
                    setLoad(false);
                })
                .catch((err) => {
                    swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã xảy ra lỗi", "error");
                    setLoad(false);
                });
        }
    };
    const selectAll = (e) => {
        if (type == 1) {
            setNewMoney(profile.money > 0 && Math.floor(profile.money).toLocaleString("vi-VN"));
        } else if (type == 2) {
            balance > 0 && setNewMoney(Math.floor(balance).toLocaleString("vi-VN"));
        } else {
            swal("Chú ý", "Vui lòng chọn chuyển tiền vào hoặc chuyển tiền ra", "warning");
        }
    };
    const openGame = () => {
        window.location.href = playGame;
    };
    return (
        <>
            {isLoading ? (
                <div className="loading">
                    <div className="loader"></div>
                </div>
            ) : null}
            <Header profile={profile} />
            {swiperParams && notify && bet?.issueList && (
                <AnimatedPage>
                    <div className="box-slide" style={{ overflow: "hidden" }}>
                        <Swiper {...swiperParams}>
                            {notify.map((item, index) => (
                                <>
                                    {index > 1 && item.title !== "marquee" && item.isShow === true ? (
                                        <SwiperSlide>
                                            <img alt={item.title.replace("}", "").replace("{", "")} src={item.image} />
                                        </SwiperSlide>
                                    ) : null}
                                </>
                            ))}
                        </Swiper>
                        {notify ? (
                            <>
                                {notify[0].isShow === true && notify[0].title === "marquee" ? (
                                    <div className="marquees">
                                        <div>
                                            <CampaignIcon sx={{ fontSize: "22px" }} />
                                        </div>
                                        <div>
                                            <div
                                                className="chaychu"
                                                style={{ animation: "chuchay " + notify[0].content.length / 10 + "s linear infinite" }}
                                                dangerouslySetInnerHTML={{
                                                    __html: notify[0].content
                                                }}
                                            />
                                        </div>
                                    </div>
                                ) : null}
                            </>
                        ) : null}
                    </div>
                    <Swiper {...swiperMenu} className="swiper-menu-game">
                        <SwiperSlide>
                            <div className={activeOption === "1" ? "active" : ""} onClick={() => handleOptionClick("1")}>
                                <img alt="" src={require("../../images/footer/game_port_table_games.png")} />
                                <img alt="" src={require("../../images/footer/game_port_circle.png")} className={activeOption === "1" ? "img-home-active" : "img-home"} />
                                <span>Đặc Sắc</span>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={activeOption === "2" ? "active" : ""} onClick={() => handleOptionClick("2")}>
                                <img alt="" src={require("../../images/footer/game_port_lode.png")} />
                                <img alt="" src={require("../../images/footer/game_port_circle.png")} className={activeOption === "2" ? "img-home-active" : "img-home"} />
                                <span>Xổ Số</span>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={activeOption === "3" ? "active" : ""} onClick={() => handleOptionClick("3")}>
                                <img alt="" src={require("../../images/footer/game_port_livecasino.png")} />
                                <img alt="" src={require("../../images/footer/game_port_circle.png")} className={activeOption === "3" ? "img-home-active" : "img-home"} />
                                <span>Live Casino</span>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={activeOption === "5" ? "active" : ""} onClick={() => handleOptionClick("5")}>
                                <img alt="" src={require("../../images/footer/game_port_slots.png")} />
                                <img alt="" src={require("../../images/footer/game_port_circle.png")} className={activeOption === "5" ? "img-home-active" : "img-home"} />
                                <span>Nổ Hũ</span>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={activeOption === "10" ? "active" : ""} onClick={() => handleOptionClick("10")}>
                                <img alt="" src={require("../../images/footer/game_port_gamebai.png")} />
                                <img alt="" src={require("../../images/footer/game_port_circle.png")} className={activeOption === "10" ? "img-home-active" : "img-home"} />
                                <span>Trò Chơi</span>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={activeOption === "6" ? "active" : ""} onClick={() => handleOptionClick("6")}>
                                <img alt="" src={require("../../images/footer/game_port_sports.png")} />
                                <img alt="" src={require("../../images/footer/game_port_circle.png")} className={activeOption === "6" ? "img-home-active" : "img-home"} />
                                <span>Thể Thao</span>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={activeOption === "7" ? "active" : ""} onClick={() => handleOptionClick("7")}>
                                <img alt="" src={require("../../images/footer/game_port_banca.png")} />
                                <img alt="" src={require("../../images/footer/game_port_circle.png")} className={activeOption === "7" ? "img-home-active" : "img-home"} />
                                <span>Bắn Cá</span>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={activeOption === "8" ? "active" : ""} onClick={() => handleOptionClick("8")}>
                                <img alt="" src={require("../../images/footer/game_port_ingame.png")} />
                                <img alt="" src={require("../../images/footer/game_port_circle.png")} className={activeOption === "8" ? "img-home-active" : "img-home"} />
                                <span>Game Bài</span>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={activeOption === "9" ? "active" : ""} onClick={() => handleOptionClick("9")}>
                                <img
                                    alt=""
                                    src={require("../../images/footer/game_port_daga2.png")}
                                    style={{ borderRadius: "100%", aspectRatio: "1", width: "100%", objectFit: "cover", transform: "scale(0.85)" }}
                                />
                                <img alt="" src={require("../../images/footer/game_port_circle.png")} className={activeOption === "9" ? "img-home-active" : "img-home"} />
                                <span>Đá Gà</span>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={activeOption === "4" ? "active" : ""} onClick={() => handleOptionClick("4")}>
                                <img alt="" src={require("../../images/footer/game_port_esports.png")} />
                                <img alt="" src={require("../../images/footer/game_port_circle.png")} className={activeOption === "4" ? "img-home-active" : "img-home"} />
                                <span>Esports</span>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                    <div style={{ padding: "0 0.32rem 2rem" }}>
                        <div className="content-game">
                            {activeOption === "1" && (
                                <div className="list-game">
                                    <img src="/newimg/img_title_xshot.png" class="img_title" style={{ marginTop: "0.32rem", marginBottom: "0.2rem" }} />
                                    <div className="box-miba miba-pro">
                                        <div className="box-miba-left" onClick={() => navigate("/xsmb/lo2")}>
                                            <img alt="" src={require("../../images/3mien/mienbac.png")} />
                                        </div>
                                        <div className="box-miba-right">
                                            <div className="box-text" style={{ fontWeight: "bold" }}>
                                                Miền Bắc <img alt="" src={require("../../img/icon-hot.png")} className="icon-hot" />
                                            </div>
                                            <div className="box-text" style={{ background: "#293356!important", padding: "0.32rem 0.32rem" }}>
                                                Ngày: {bet?.issueList[0]?.turnNum}
                                            </div>
                                            <div className="box-miba-kq" style={{ padding: "0" }}>
                                                {bet?.issueList[0]?.openNum.split(",").map((x) => (
                                                    <div className="ball">
                                                        <img alt="" src={`/images/ball/${x}.png`} />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <img src="/newimg/img_title_thethao.png" class="img_title" />
                                    <Swiper {...swiperGame}>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={() => launchGame({ product_type: "142", game_code: "FB0001" })}>
                                                <img alt="" src="/newimg/sports_home_fbsports.png" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={() => launchGame({ product_type: "174", game_code: "SB0001" })}>
                                                <img alt="" src="/newimg/sports_sabasport_home.png" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={() => launchGame({ product_type: "104", game_code: "CMD001" })}>
                                                <img alt="" src="/newimg/sports_home_cmd368.png" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={() => launchGame({ product_type: "54", game_code: "SBO041" })}>
                                                <img alt="" src="/newimg/sports_sportsbook_home.png" />
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                    <img src="/newimg/img_title_casino.png" class="img_title" />
                                    <Swiper {...swiperGame}>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={() => launchGame({ product_type: "118", game_code: "WM0001" })}>
                                                <img alt="" src="/newimg/wm.png" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={() => launchGame({ product_type: "112", game_code: "SEX001" })}>
                                                <img alt="" src="/newimg/s2.png" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={() => launchGame({ product_type: "27", game_code: "DG0013" })}>
                                                <img alt="" src="/newimg/dg.png" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={() => launchGame({ product_type: "4", game_code: "A00070" })}>
                                                <img alt="" src="/newimg/ag.png" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={() => launchGame({ product_type: "86", game_code: "EG0001" })}>
                                                <img alt="" src="/newimg/eg.png" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={() => launchGame({ product_type: "111", game_code: "PTU002" })}>
                                                <img alt="" src="/newimg/pt.png" />
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                    <img src="/newimg/img_title_slots.png" class="img_title" />
                                    <Swiper {...swiperGame}>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={() => menuGame("Nổ hũ", "140", "RNG")}>
                                                <img alt="" src={require("../../images/games/jili.png")} />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={() => menuGame("Nổ hũ", "4", "RNG")}>
                                                <img alt="" src={require("../../images/games/slotag.png")} />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={() => menuGame("Nổ hũ", "16", "RNG")}>
                                                <img alt="" src={require("../../images/games/cq9.png")} />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={() => menuGame("Nổ hũ", "79", "RNG")}>
                                                <img alt="" src={require("../../images/games/bbin.png")} />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={() => menuGame("Nổ hũ", "39", "RNG")}>
                                                <img alt="" src={require("../../images/games/pp.png")} />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={() => menuGame("Nổ hũ", "43", "RNG")}>
                                                <img alt="" src={require("../../images/games/mg.png")} />
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                    <img src="/newimg/img_title_banca.png" class="img_title" />
                                    <Swiper {...swiperGame3}>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={() => menuGame("Bắn cá", "140", "FISH")}>
                                                <img alt="" src="/newimg/fish_jilifish_main.png" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={() => menuGame("Bắn cá", "55", "FISH")}>
                                                <img alt="" src="/newimg/fish_jdbfish_main.png" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={() => launchGame({ product_type: "16", game_code: "CQ0305" })}>
                                                <img alt="" src="/newimg/fish_cq9fish_main.png" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={() => menuGame("Bắn cá", "43", "FISH")}>
                                                <img alt="" src="/newimg/fish_mgfish_main.png" />
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                    <img src="/newimg/img_title_gamebai.png" class="img_title" />
                                    <Swiper {...swiperGame7}>
                                        <SwiperSlide>
                                            <div className="api-game border" onClick={() => launchGame({ product_type: "102", game_code: "LCC001" })}>
                                                <img alt="" src={require("../../images/games/v8poker.png")} />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="api-game border" onClick={() => launchGame({ product_type: "121", game_code: "KM0001" })}>
                                                <img alt="" src={require("../../images/games/kingmaker.png")} />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="api-game border" onClick={() => launchGame({ product_type: "123", game_code: "KP0001" })}>
                                                <img alt="" src={require("../../images/games/kingpoker.png")} />
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            )}
                            {activeOption === "2" && (
                                <div className="list-game" style={{ marginTop: "20px" }}>
                                    <div className="box-miba">
                                        <div className="box-miba-left" onClick={() => navigate("/xsmb/lo2")}>
                                            <img alt="" src={require("../../images/3mien/mienbac.png")} />
                                            <div className="dat-cuoc" onClick={() => navigate("/xsmb/lo2")}>
                                                Đặt cược
                                            </div>
                                        </div>
                                        <div className="box-miba-right">
                                            <div className="box-text">
                                                Miền Bắc <img alt="" src={require("../../img/icon-hot.png")} className="icon-hot" />
                                            </div>
                                            <div className="box-text">Ngày: {bet?.issueList[0]?.turnNum}</div>
                                            <div className="box-miba-kq">
                                                {bet?.issueList[0]?.openNum.split(",").map((x) => (
                                                    <div className="ball">
                                                        <img alt="" src={`/images/ball/${x}.png`} />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="title">Xổ số nhanh >></div>
                                    <div className="list-game-xs">
                                        <Link to="/xs75s/lo2">
                                            <img alt="" src={require(`../../images/mb75s.png`)} />
                                        </Link>
                                        <Link to="/xs120s/lo2">
                                            <img alt="" src={require(`../../images/st2p.png`)} />
                                        </Link>
                                    </div>

                                    <div className="title" onClick={() => navigate("/xoso")}>
                                        Miền Nam >>
                                    </div>
                                    <div className="list-game-xs">
                                        {dataMN?.map((item, index) => (
                                            <>
                                                <Link to={`/xsmn/lo2/${item}`} key={item}>
                                                    <img alt="" src={require(`../../images/3mien/${imageMN[index]}.png`)} />
                                                </Link>
                                            </>
                                        ))}
                                    </div>
                                    <div className="title" onClick={() => navigate("/xoso")}>
                                        Miền Trung >>
                                    </div>
                                    <div className="list-game-xs">
                                        {dataMT?.map((item, index) => (
                                            <>
                                                <Link to={`/xsmt/lo2/${item}`} key={item}>
                                                    <img alt="" src={require(`../../images/3mien/${imageMT[index]}.png`)} />
                                                </Link>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            )}
                            {activeOption === "3" && (
                                <>
                                    <div className="api-game border" onClick={() => launchGame({ product_type: "118", game_code: "WM0001" })}>
                                        <img alt="" src={require("../../images/games/live_wm.png")} />
                                    </div>
                                    <div className="api-game border" onClick={() => launchGame({ product_type: "27", game_code: "DG0013" })}>
                                        <img alt="" src={require("../../images/games/live_dg.png")} />
                                    </div>
                                    <div className="api-game border" onClick={() => launchGame({ product_type: "112", game_code: "SEX001" })}>
                                        <img alt="" src={require("../../images/games/live_sexy.png")} />
                                    </div>
                                    <div className="api-game border" onClick={() => launchGame({ product_type: "86", game_code: "EG0001" })}>
                                        <img alt="" src={require("../../images/games/live_evo.png")} />
                                    </div>
                                    <div className="api-game border" onClick={() => launchGame({ product_type: "4", game_code: "A00070" })}>
                                        <img alt="" src={require("../../images/games/live_ag.png")} />
                                    </div>
                                    <div className="api-game border" onClick={() => launchGame({ product_type: "54", game_code: "SBO044" })}>
                                        <img alt="" src={require("../../images/games/live_sbo.png")} />
                                    </div>
                                    <div className="api-game border" onClick={() => launchGame({ product_type: "43", game_code: "MG0001" })}>
                                        <img alt="" src={require("../../images/games/live_mg.png")} />
                                    </div>
                                    <div className="api-game border" onClick={() => launchGame({ product_type: "111", game_code: "PTU002" })}>
                                        <img alt="" src={require("../../images/games/live_pt.png")} />
                                    </div>
                                </>
                            )}
                            {activeOption === "4" && (
                                <>
                                    <div className="api-game border" onClick={() => launchGame({ product_type: "99", game_code: "TF0001" })}>
                                        <div className="img-esports esport1"></div>
                                    </div>
                                    <div className="api-game border">
                                        <div className="img-esports esport2">
                                            <div className="maintain">Đang bảo trì</div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {activeOption === "5" && (
                                <div className="api-game-flex">
                                    <div className="api-game" onClick={() => menuGame("Nổ hũ", "140", "RNG")}>
                                        <img alt="" src={require("../../images/games/jili.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => menuGame("Nổ hũ", "4", "RNG")}>
                                        <img alt="" src={require("../../images/games/slotag.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => menuGame("Nổ hũ", "16", "RNG")}>
                                        <img alt="" src={require("../../images/games/cq9.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => menuGame("Nổ hũ", "79", "RNG")}>
                                        <img alt="" src={require("../../images/games/bbin.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => menuGame("Nổ hũ", "39", "RNG")}>
                                        <img alt="" src={require("../../images/games/pp.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => menuGame("Nổ hũ", "43", "RNG")}>
                                        <img alt="" src={require("../../images/games/mg.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => menuGame("Nổ hũ", "55", "RNG")}>
                                        <img alt="" src={require("../../images/games/jdb.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => menuGame("Nổ hũ", "111", "RNG")}>
                                        <img alt="" src={require("../../images/games/pt.png")} />
                                    </div>
                                </div>
                            )}
                            {activeOption === "6" && (
                                <div className="api-game-flex">
                                    <div className="api-game border" onClick={() => launchGame({ product_type: "174", game_code: "SB0001" })}>
                                        <img alt="" src={require("../../images/games/saba2.png")} />
                                    </div>
                                    <div className="api-game border" onClick={() => launchGame({ product_type: "151", game_code: "UG2001" })}>
                                        <img alt="" src={require("../../images/games/ug2.png")} />
                                    </div>
                                    <div className="api-game border" onClick={() => launchGame({ product_type: "54", game_code: "SBO041" })}>
                                        <img alt="" src={require("../../images/games/sbo2.png")} />
                                    </div>
                                    <div className="api-game border" onClick={() => launchGame({ product_type: "142", game_code: "FB0001" })}>
                                        <img alt="" src={require("../../images/games/fb2.png")} />
                                    </div>
                                    <div className="api-game border" onClick={() => launchGame({ product_type: "104", game_code: "CMD001" })}>
                                        <img alt="" src={require("../../images/games/cmd2.png")} />
                                    </div>
                                    <div className="api-game border">
                                        <img alt="" src={require("../../images/games/sbov2.png")} />
                                        <div className="maintain" style={{ maxWidth: "calc(100% - 0.32rem)" }}>
                                            Đang bảo trì
                                        </div>
                                    </div>
                                </div>
                            )}
                            {activeOption === "7" && (
                                <>
                                    <div className="api-game border" onClick={() => menuGame("Bắn cá", "140", "FISH")}>
                                        <img alt="" src={require("../../images/games/fishing_jili.png")} />
                                    </div>
                                    <div className="api-game border" onClick={() => menuGame("Bắn cá", "55", "FISH")}>
                                        <img alt="" src={require("../../images/games/fishing_jdb.png")} />
                                    </div>
                                    <div className="api-game border" onClick={() => launchGame({ product_type: "16", game_code: "CQ0305" })}>
                                        <img alt="" src={require("../../images/games/fishing_cq9.png")} />
                                    </div>
                                    <div className="api-game border" onClick={() => menuGame("Bắn cá", "43", "FISH")}>
                                        <img alt="" src={require("../../images/games/fishing_mg.png")} />
                                    </div>
                                    <div className="api-game border" onClick={() => menuGame("Bắn cá", "111", "FISH")}>
                                        <img alt="" src={require("../../images/games/fishing_pt.png")} />
                                    </div>
                                </>
                            )}
                            {activeOption === "8" && (
                                <>
                                    <div className="api-game border" onClick={() => launchGame({ product_type: "102", game_code: "LCC001" })}>
                                        <img alt="" src={require("../../images/games/v8poker.png")} />
                                    </div>
                                    <div className="api-game border" onClick={() => launchGame({ product_type: "121", game_code: "KM0001" })}>
                                        <img alt="" src={require("../../images/games/kingmaker.png")} />
                                    </div>
                                    <div className="api-game border" onClick={() => launchGame({ product_type: "123", game_code: "KP0001" })}>
                                        <img alt="" src={require("../../images/games/kingpoker.png")} />
                                    </div>
                                </>
                            )}
                            {activeOption === "9" && (
                                <>
                                    <div className="api-game border" onClick={() => launchGame({ product_type: "202", game_code: "WS1682" })}>
                                        <img alt="" src={require("../../images/games/ws168.png")} />
                                    </div>
                                    <div className="api-game border">
                                        <img alt="" src={require("../../images/games/ga28.png")} />
                                        <div className="maintain">Đang bảo trì</div>
                                    </div>
                                </>
                            )}
                            {activeOption === "10" && (
                                <>
                                    <div class="api-game-flex">
                                        <Link className="api-game" to="/xd60s">
                                            <img alt="" src={require("../../images/xocdia60s.png")} />
                                        </Link>
                                        <Link className="api-game" to="/xd45s">
                                            <img alt="" src={require("../../images/xocdia45s.png")} />
                                        </Link>
                                    </div>
                                </>
                            )}
                            {/*activeOption !== "1" && (
                                <div className="baohanh">
                                    <img alt="" src={require("../../images/bao-hanh-va-sua-chua.png")} style={{ maxWidth: "8rem" }} />
                                    <div className="nangcap">Hệ thống đang nâng cấp...</div>
                                </div>
                            )*/}
                        </div>
                    </div>
                </AnimatedPage>
            )}
            <Footer />
            {isShow2 === true ? (
                <>
                    <div className="popup-backdrop">
                        <div className="popup-main">
                            <div className="popup-header">{title}</div>
                            <div className="popup-content">
                                <div className="gameapi-list">
                                    {game != null &&
                                        game.map((item) => (
                                            <div className="gameapi-item" onClick={() => launchGame({ product_type: item.productType, game_code: item.tcgGameCode })}>
                                                <img src={`https://images.21947392.com/TCG_GAME_ICONS/${item.productCode}/VI/${item.tcgGameCode}.png`} alt={item.gameName} />
                                                <div class="gameapi-name">{item.gameName}</div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                            <button onClick={() => setShow2(false)} className="popup-close">
                                Đóng
                            </button>
                        </div>
                    </div>
                </>
            ) : null}
            {playGame && profile ? (
                <div className="popup-backdrop">
                    <div className="popup-main">
                        <div className="popup-close-top" onClick={() => setPlayGame(null)}>
                            <CloseIcon sx={{ fontSize: "26px", color: "#fff" }} />
                        </div>
                        <div className="popup-header">Sảnh {sanh}</div>
                        <div className="popup-content" style={{ textAlign: "center" }}>
                            <div style={{ color: "#000", marginTop: "20px", fontSize: "16px" }}>
                                Số dư tài khoản: <b>{profile?.money?.toLocaleString("vi-VN")}</b>
                            </div>
                            <form className="form-lg" onSubmit={handleSubmit(onSubmit)}>
                                <div style={{ color: "#000", fontSize: "16px", margin: "10px 0 20px" }}>
                                    Số dư sảnh {sanh}: {balance?.toLocaleString("vi-VN")}
                                </div>
                                <div style={{ display: "flex", color: "#000", justifyContent: "space-around", marginBottom: "10px" }}>
                                    <div>
                                        <input type="radio" name="type_money" value="1" onChange={(e) => setType(1)} />
                                        Chuyển quỹ vào
                                    </div>
                                    <div>
                                        <input type="radio" name="type_money" value="2" onChange={(e) => setType(2)} />
                                        Chuyển quỹ ra
                                    </div>
                                </div>
                                {type == 1 && newMoney != null && (
                                    <div style={{ margin: "10px 0", fontSize: "14px" }}>
                                        Số tiền nhận được trong game: <b>{newMoney ? (Number(newMoney.replaceAll(".", "")) / 1000).toLocaleString("vi-VN") : 0}</b>
                                    </div>
                                )}
                                {type == 2 && newMoney != null && (
                                    <div style={{ margin: "10px 0", fontSize: "14px" }}>
                                        Số tiền nhận vào ví chính: <b>{newMoney ? (Number(newMoney.replaceAll(".", "")) * 1000).toLocaleString("vi-VN") : 0}</b>
                                    </div>
                                )}
                                <div className="relative-input">
                                    <input
                                        className="ipadd"
                                        type="text"
                                        {...register("money", { required: true })}
                                        placeholder="Nhập số tiền"
                                        value={newMoney}
                                        onClick={() => setNewMoney(null)}
                                        onChange={(e) => setNewMoney(Number(e.target.value.replaceAll(".", "").replaceAll(",", "")).toLocaleString("vi-VN"))}
                                    />
                                    <div className="absolute-input" onClick={selectAll}>
                                        Tất cả
                                    </div>
                                </div>
                                <button type="submit" className={load === true ? "btn-submit loader" : "btn-submit"}>
                                    Xác nhận
                                </button>
                            </form>
                            <button className="open-game" onClick={openGame}>
                                Vào game ngay
                            </button>
                        </div>
                    </div>
                </div>
            ) : null}
            {profile && notify && isShow && notifyParam != null && (
                <>
                    {notify.map(
                        (item) =>
                            item.isShow &&
                            item.title === "popup" && (
                                <>
                                    <div className="popup-backdrop">
                                        <div className="popup-main">
                                            <div className="popup-times" onClick={() => setShow(false)}>
                                                &times;
                                            </div>
                                            <div className="popup-content" style={{ padding: "20px" }}>
                                                {item.content.includes("{") && item.content.includes("}") ? (
                                                    <img
                                                        src={item.content.substring(item.content.indexOf("{") + 1, item.content.indexOf("}"))}
                                                        style={{ width: "100%", height: "auto" }}
                                                    />
                                                ) : (
                                                    <div dangerouslySetInnerHTML={{ __html: item.content }} />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                    )}
                </>
            )}
        </>
    );
}
export default Home;
